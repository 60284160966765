<kendo-combobox
  [data]="gridViewList"
  [allowCustom]="true"
  [(ngModel)]="selectedGridView">

  <kendo-combobox-messages
    clearTitle="Clear Selected Grid View"
  >
  </kendo-combobox-messages>

  <ng-template kendoPrefixTemplate>
    <kendo-svgicon title="Manage Grid Views" [icon]="svgViewIcon"></kendo-svgicon>
  </ng-template>

  <ng-template kendoSuffixTemplate>
    <button kendoButton title="Load Grid View" [svgIcon]="svgViewIcon" (click)="onGridViewLoad()" [hidden]="hideLoadGridView"></button>
    <button kendoButton title="Save Grid View" [svgIcon]="svgSaveIcon" (click)="onGridViewUpsert()" [hidden]="hideSaveGridView"></button>
    <button kendoButton title="Lock Grid View" [svgIcon]="svgLockIcon" (click)="onGridViewLockState(true)" [hidden]="hideLockGridView"></button>
    <button kendoButton title="Unlock Grid View" [svgIcon]="svgUnlockIcon" (click)="onGridViewLockState(false)" [hidden]="hideUnlockGridView"></button>
    <button kendoButton title="Delete Grid View" [svgIcon]="svgTrashIcon" (click)="onGridViewDelete()" [hidden]="hideDeleteGridView"></button>
  </ng-template>
</kendo-combobox>
