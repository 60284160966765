import { CompositeFilterDescriptor, SortDescriptor } from "@progress/kendo-data-query";
import { PagerSettings } from "@progress/kendo-angular-grid";
import { IPagedResponse } from "@aex/ngx-toolbox";

export interface ISearchData{}
export interface IAdvanceGridSearch{}

export interface IGridState {
  lockState: boolean;

  columns: GridColumnItem[];
  selectableColumns : string[];
  selectedColumns: string[];
  visualColumnOrder: string[];

  sort: SortDescriptor[];

  filter: CompositeFilterDescriptor;

  pageSize: number;
  skip: number;
  buttonCount : number;
  sizes : number[];
  currentPage : number;
  pageSettings : PagerSettings;

  searchKeyword: string;

  hasAdvancedSearch: boolean;
  advanceFilterActive: boolean;
  advGridSearch?: IAdvanceGridSearch;

}

export type ColumnDataType = 'string' | 'number' | 'boolean' | 'date' | 'dateTime' | 'hyperlink' | 'html' | 'list';

export type FilterDataType = 'text' | 'numeric' | 'boolean' | 'date';

// Promotions Grid Data Item
export class GridColumnItem {
  public field : string = '';
  public sub_field : string = '';
  public title : string = '';
  public data_type : ColumnDataType = 'string';
  public filterable : boolean = true;
  public filter : FilterDataType = 'text';

  public editable : boolean = false;

  public hidden : boolean = false;
  public width : number = 150;

  public get comboField(): string {
    return this.field + '.' + this.sub_field;
  }

  constructor(init?: Partial<GridColumnItem>) {
    Object.assign(this, init); // Merge provided values with defaults
  }

}

export class GridState implements IGridState{
	public gridViewStateName: string;
  public lockState: boolean = false;

	public columns: GridColumnItem[] = [];
	public selectableColumns : string[] = [];
	public selectedColumns: string[] = [];
  public visualColumnOrder: string[] = [];

  public sort: SortDescriptor[] = [];

  public filter: CompositeFilterDescriptor;

  public pageSize: number;
  public skip: number;
  public buttonCount : number;
  public sizes : number[];
  public currentPage : number;
  public pageSettings : PagerSettings;

  public searchKeyword: string;

	// Has advanced search - indicates whether the flag
  public hasAdvancedSearch: boolean = false;
  public advanceFilterActive: boolean = false;
  public advGridSearch?: IAdvanceGridSearch = null;
}

export function isGridStateIdentical(sourceGridState: GridState, targetGridState: GridState): boolean {
  return JSON.stringify(sourceGridState) === JSON.stringify(targetGridState);
}

export type LoadGridStateRequest = {
	gridViewStateName: string,
  gridState: GridState|null,
  reloadData: boolean,
	clearData: boolean
}

export const PAGE_SIZE_SMALL = 5;
export const PAGE_SIZE_MEDIUM = 10;
export const PAGE_SIZE_LARGE = 20;
export const PAGE_SIZE_XLARGE = 50;
export const PAGE_SIZE_XXLARGE = 100;

export const PAGE_SIZES = [
  PAGE_SIZE_SMALL,
  PAGE_SIZE_MEDIUM,
  PAGE_SIZE_LARGE,
  PAGE_SIZE_XLARGE,
  PAGE_SIZE_XXLARGE,
];

export enum FormMode {
  New = 1,
  Edit = 2,
  View = 3,
}

export function FormModeAsText(mode: FormMode): string {
  switch (mode) {
    case FormMode.New:    return 'New';
    case FormMode.Edit:   return 'Edit';
    case FormMode.View:   return 'View';
    default:              return '';
  }
}

export interface ActionItem<T> {
  fieldName: string;
  dataItem: T;
}

export class GridPageChangeEmitData {
  page: number;
  pageSize: number;
}

export function EmptyPagedResponse<T>(): IPagedResponse<T> {
  return {
    items: [],
    page: 0,
    count: 0,
    total: 0,
  };
}
