import { Exclude } from "class-transformer";

export enum TDateRangeOperator {
  Before     = 1,     // Before the selected date
  BeforeOrOn = 2,     // Before or on the selected date
  After      = 3,     // After the selected date
  AfterOrOn  = 4,     // After or on the selected date
  Between    = 5,     // Between the selected dates
  NotBetween = 6,     // Not between the selected dates
  On         = 7,     // On the selected date
}

export enum TDateRangeFilterDataType {
  ActivationDate              = 1,    // Activation Date
  ApplicationDate             = 2,    // Application Date
  CancellationDate            = 3,    // Cancellation Date
  PeriodEndDate               = 4,    // Period End Date
  SuspensionDate              = 5,    // Suspension Date
  PromoDiscountStartDate      = 6,    // Promo Discount Date
  PromoDiscountEndDate        = 7,    // Promo Discount End Date
  PromoDiscountPublishedDate  = 8,    // Promo Discount Published Date
  ServiceCreatedDate          = 9,    // Service Created Date
}

export function filterDataTypeAsText(filterDataType: TDateRangeFilterDataType): string {
  switch (filterDataType) {
    case TDateRangeFilterDataType.ActivationDate:             return 'Activation Date';
    case TDateRangeFilterDataType.ApplicationDate:            return 'Application Date';
    case TDateRangeFilterDataType.CancellationDate:           return 'Cancellation Date';
    case TDateRangeFilterDataType.PeriodEndDate:              return 'Period End Date';
    case TDateRangeFilterDataType.SuspensionDate:             return 'Suspension Date';
    case TDateRangeFilterDataType.PromoDiscountStartDate:     return 'Promo Start Date';
    case TDateRangeFilterDataType.PromoDiscountEndDate:       return 'Promo End Date';
    case TDateRangeFilterDataType.PromoDiscountPublishedDate: return 'Promo Published Date';
    
    case TDateRangeFilterDataType.ServiceCreatedDate:         return 'Service Created Date';
    default:                                                  return '';
  }
}

export function dateRangeOperatorAsText(dateRangeOperator: TDateRangeOperator): string {
  switch (dateRangeOperator) {
    case TDateRangeOperator.Before:     return 'Before';
    case TDateRangeOperator.BeforeOrOn: return 'Before or on';
    case TDateRangeOperator.After:      return 'After';
    case TDateRangeOperator.AfterOrOn:  return 'After or on';
    case TDateRangeOperator.Between:    return 'Between';
    case TDateRangeOperator.NotBetween: return 'Not Between';
    case TDateRangeOperator.On:         return 'On';
    default:                            return '';
  }
}

export interface IDateRangeSelector {
  selected: boolean;
  filter_data_type: TDateRangeFilterDataType;
  date_range_operator: TDateRangeOperator;
  date_range_operator_id: number;
  date_start_value?: Date | null;
  date_end_value?: Date | null;
  date_start: string;
  date_end: string;
  display_filter_data_type: string;
  display_date_range_operator: string;
}
export abstract class DateRangeSelectorItem implements IDateRangeSelector {
  public selected: boolean = false;
  public date_range_operator_id = 5;
  @Exclude()
  public get filter_data_type() : TDateRangeFilterDataType {
    return this.getFilterDataType();
  }
  public date_range_operator: TDateRangeOperator = TDateRangeOperator.After;
  public date_start_value?: Date | null;
  public date_end_value?: Date | null;
  public date_start: string;
  public date_end: string;

  @Exclude()
  public get display_filter_data_type(): string {
    return filterDataTypeAsText(this.filter_data_type)
  }
  @Exclude()
  public display_date_range_operator: string;

  protected abstract getFilterDataType(): TDateRangeFilterDataType;
}

export class ActivationDateRangeSelectorItem extends DateRangeSelectorItem {
  protected getFilterDataType() : TDateRangeFilterDataType {
    return TDateRangeFilterDataType.ActivationDate;
  }
}

export class ApplicationDateRangeSelectorItem extends DateRangeSelectorItem {
  protected getFilterDataType() : TDateRangeFilterDataType {
    return TDateRangeFilterDataType.ApplicationDate;
  }
}

export class CancellationDateRangeSelectorItem extends DateRangeSelectorItem {
  protected getFilterDataType() : TDateRangeFilterDataType {
    return TDateRangeFilterDataType.CancellationDate;
  }
}

export class PeriodEndDateRangeSelectorItem extends DateRangeSelectorItem {
  protected getFilterDataType() : TDateRangeFilterDataType {
    return TDateRangeFilterDataType.PeriodEndDate;
  }
}

export class SuspensionDateRangeSelectorItem extends DateRangeSelectorItem {
  protected getFilterDataType() : TDateRangeFilterDataType {
    return TDateRangeFilterDataType.SuspensionDate;
  }
}

export class PromoDiscountStartDateRangeItem extends DateRangeSelectorItem {
  protected getFilterDataType(): TDateRangeFilterDataType {
    return TDateRangeFilterDataType.PromoDiscountStartDate;
  }
}

export class PromoDiscountEndDateRangeItem extends DateRangeSelectorItem {
  protected getFilterDataType(): TDateRangeFilterDataType {
    return TDateRangeFilterDataType.PromoDiscountEndDate;
  }
}

export class PromoDiscountPublishedDateRangeItem extends DateRangeSelectorItem {
  protected getFilterDataType(): TDateRangeFilterDataType {
    return TDateRangeFilterDataType.PromoDiscountPublishedDate;
  }
}

export class ServiceCreatedDateRangeItem extends DateRangeSelectorItem {
  protected getFilterDataType() : TDateRangeFilterDataType {
    return TDateRangeFilterDataType.ServiceCreatedDate;
  }
}
