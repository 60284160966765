import {
  FormGroup,
  FormControl,
  FormBuilder,
  ValidatorFn,
  AbstractControl,
  ValidationErrors,
} from "@angular/forms";
import {
  ColumnComponent,
  GridComponent,
} from "@progress/kendo-angular-grid";
import { GridColumnItem } from "../interfaces/kendo-grid";
import { instanceToInstance } from "class-transformer";
import {FilterEditor} from "@progress/kendo-angular-filter/model/filter-expression";

export const DATE_COLUMN_WIDTH = 50;
export const DATE_TIME_COLUMN_WIDTH = 60;
export const SHORT_DESCRIPTION_COLUMN_WIDTH = 40;
export const NAME_COLUMN_WIDTH = 70;

export class KendoHelper{

  public static buildFormGroupFromGrid(
    formBuilder: FormBuilder,
    grid : GridComponent,
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataItem?: any,
  ): FormGroup {
    const group: { [key: string]: FormControl } = {};

    grid.columns.toArray().forEach((column) => {
      const colComponent = column as ColumnComponent; // Cast to ColumnComponent
      let columnValue: string;
      if (dataItem)
        columnValue = dataItem[colComponent.field] ?? ''; // Get the value from the dataItem
      else
        columnValue = '';

      // Ensure the column has a field name
      if (colComponent.field)
        group[colComponent.field] = new FormControl(
          { value: columnValue, disabled: !colComponent.editable }, // Disable if not editable
        );
    });

    return formBuilder.group(group); // Use FormBuilder to create a FormGroup
  }

  public static rangeValidator(min: number, max: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

			if (value === null)
				return null;

      // Check if the value is a number and within the specified range
      if (isNaN(value) || value < min || value > max)
        return { outOfRange: { min, max } }; // Return an error object

      return null; // Return null if valid
    };
  }

	//eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static uniqueValueValidator(list: any[], key: string, currentId? : number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value?.toString().toLowerCase();
      const exists = list.some(item =>
        (item[key]?.toString().toLowerCase() === value) &&
        (item.id !== currentId),           // Check if the value already exists in the list
      );

      return exists ? { uniqueValue: { value: control.value } } : null;
    };
  }

}

export function getGridVisibleColumnOrder(grid: GridComponent): string[] {
  // Extract visible columns with a field and orderIndex
  const visibleColumns = grid.columns.toArray()
    .filter((column) => {
      const colComponent = column as ColumnComponent;
      return colComponent?.title && !colComponent.hidden;
    })
    .map((column) => {
      const colComponent = column as ColumnComponent;
      return { field: colComponent.title, orderIndex: colComponent.orderIndex };
    });

  // Sort by orderIndex and extract field names
  return visibleColumns
    .sort((a, b) => a.orderIndex - b.orderIndex)
    .map((obj) => obj.field);
}

export function restoreGridVisibleColumnOrder(currentVisibleColumns : GridColumnItem[], visualColumnOrder: string[]): GridColumnItem[] {
  const result : GridColumnItem[] = [];
  visualColumnOrder.forEach((field) => {

    let col : GridColumnItem|undefined;
    col = currentVisibleColumns.find((c) => c.title === field);

    if (col) {
      const clone = instanceToInstance(col);
      clone.hidden = false;
      result.push(clone);
    }
  });
  return result;
}

export function arrangeVisibleColumnsFirst(gridColumns : GridColumnItem[], visualColumnTitles: string[]): void {
  gridColumns.sort((a, b) => {
    const indexA = visualColumnTitles.indexOf(a.title);
    const indexB = visualColumnTitles.indexOf(b.title);

    // If both are in the visualColumnTitles, sort by index
    if (indexA !== -1 && indexB !== -1)
      return indexA - indexB;

    // If only A is visible, it should come before B
    if (indexA !== -1)
      return -1;

    // If only B is visible, it should come before A
    if (indexB !== -1)
      return 1;

    // Both are hidden (or neither in visualColumnTitles), keep their order as is
    return 0;
  });
}

  export class IYesNo {
  id: boolean;
  name: string;
}

export function booleanList(): IYesNo[] {
  return [
    { id: false, name: 'No' },
    { id: true, name: 'Yes' },
  ];
}

export const RANDOM_CHARACTERS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ12345679';

export function generateRandomString(length: number): string {
  let result = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * RANDOM_CHARACTERS.length);
    result += RANDOM_CHARACTERS.charAt(randomIndex);
  } return result;
}

// Check if two arrays are identical
export function areArraysIdentical<T extends string | number>(sourceArray: T[], targetArray: T[]): boolean {
  return sourceArray.length === targetArray.length &&
    sourceArray.every((value, index) => value === targetArray[index]);
}

// Remove duplicates from an array
export function unionArrays(array1: string[], array2: string[]): string[] {
  return Array.from(new Set([...array1, ...array2]));
}

// The type parameter T must have a 'name' property of type string and an 'id' property of type string or number
export function filterSublist<T extends { name: string; id: string | number }>(
  sourceList: T[],
  searchTerm: string,
): T[] {
  const contains = (value: string) => {
    return (item: T) => item.name.toLowerCase().includes(value.toLowerCase());
  };
  return sourceList.filter(contains(searchTerm));
}

export function getKendoEditorType(dataType: string): FilterEditor {
	const editorMapping: { [key: string]: FilterEditor } = {
		string: 	'string',
		number: 	'number',
		boolean: 	'boolean',
		date: 		'date',
	};
	return editorMapping[dataType] || 'string'; // Default to 'string' for unknown types
}
