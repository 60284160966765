export class DateHelper {
	public static formatDate(value: Date, separator: string = '-'): string {
		const date = new Date(value);
		const year = date.getFullYear();
		const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-based
		const day = date.getDate().toString().padStart(2, '0');
		return `${year}${separator}${month}${separator}${day}`;
	}
}

export function secondsToTime(seconds : number) : string {
	const hours = Math.floor(seconds / 3600);
	const minutes = Math.floor((seconds % 3600) / 60);
	const remainingSeconds = seconds % 60;
	return `${hours}h ${minutes}m ${remainingSeconds}s`;
}

export function bytesToSize(bytes : number) : string {
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'EB', 'ZB', 'YB'];
	if (bytes === 0) return '0 Byte';
	const i = Math.floor(Math.log(bytes) / Math.log(1024));
	return `${parseFloat((bytes / Math.pow(1024, i)).toFixed(2))} ${sizes[i]}`;
}

// Date Only without time
export function dateOnly(date: Date): Date {
	return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

export function startOfMonth(date: Date): Date {
	return new Date(date.getFullYear(), date.getMonth(), 1);
}

export function endOfMonth(date: Date): Date {
	return new Date(date.getFullYear(), date.getMonth() + 1, 0);
}

export function endOfLastMonth(date: Date): Date {
	return new Date(date.getFullYear(), date.getMonth(), 0);
}