//#region --- Promo Report Search Request DTO ---

import { Type } from "class-transformer";
import { IAdvanceGridSearch } from "./kendo-grid";
import { DateHelper } from "../helpers/date-helper";

export class PromoReportSearchRequestDto {
  promo_discount_ids?: string[];
  promo_price_reduction_type_id?: number;
  service_ids?: string[];
  purchase_status_ids?: number[];
  purchase_ids?: string[];
  @Type(() => Date)  
  summary_start_date?: Date;
  @Type(() => Date)  
  summary_end_date?: Date;
  page_no?: number;
  page_size?: number;

  constructor(init?: Partial<PromoReportSearchRequestDto>) {
    Object.assign(this, init);
  }

  public clearFilters(): void {
    this.promo_discount_ids = [];
    this.promo_price_reduction_type_id = null;
    this.service_ids = [];
    this.purchase_status_ids = [];
    this.summary_start_date = null;
    this.summary_end_date = null;
    this.page_no = null;
    this.page_size = null;
  }  
}

export class PromoReportSummaryAdvSearchFilter extends PromoReportSearchRequestDto implements IAdvanceGridSearch{  
}

//#endregion --- Promo Report Search Request DTO ---

//#region --- Promo Report Data DTOs ---
export class PromoSummaryDto {
  promo_discount_id: string;
  promo_name: string;
  promo_price_reduction_name: string;
  promo_discount_category_id: number;
  promo_discount_category_name: string;
  product_price: number;
  promo_adjustment: number;
  applied_promo_adjustment: number;
  final_amount: number;
  reported_usage_count: number;               // Usage count of the promo discount for the report period
  total_usage_count: number;                  // Total usage count of the promo discount
  remaining_usage_count: number;              // Estimated remaining usage count of the promo discount
}

export class PromoSummaryServiceDto extends PromoSummaryDto {
  service_id: string;
  customer_full_name: string;
  customer_email: string;
  product_name: string;
  service_status_name: string;
  area_name: string;
}

export class PromoSummaryServicePurchaseDto extends PromoSummaryServiceDto {
  @Type(() => Date)  
  purchase_date: Date;
  purchase_id: string;
  amount: number;
  purchase_status_id: number;
  purchase_status_name: string;
}

export class PromoServicePurchaseInfoDto {
  purchase_id: string;
  @Type(() => Date)  
  purchase_date: Date;
  total_amount: number;
  purchase_status_id: number;
  purchase_status_name: string;
  promo_purchase_lines: PromoServicePurchaseDetailLineDto[] = [];

  public get displayPurchaseDate(): string {
    return this.purchase_date ? DateHelper.formatDate(this.purchase_date, '/') : '';
  }
}

export class PromoServicePurchaseDetailLineDto {
  purchase_line_id: string;
  description: string;
  quantity: number;
  amount_per_quantity: number;
  amount: number;
  purchase_line_type_id: number;
  purchase_line_type_name: string;
  promo_discount_id?: string;
  promo_discount_name?: string;
  is_hidden: boolean;
}

//#endregion --- Promo Report Data DTOs ---

//#region --- Promo Report Response DTOs ---

// export class PromoSummaryResponseDto {
//   promo_summary_list: PromoSummaryDto[] = [];
// }

// export class PromoSummaryServiceResponseDto {
//   promo_summary_by_service_list: PromoSummaryServiceDto[] = [];
// }

// export class PromoSummaryServicePurchaseResponseDto {
//   promo_summary_by_service_purchase_list: PromoSummaryServicePurchaseDto[] = [];
// }

// export class PromoSummaryServicePeriodPurchaseInfoResponseDto {
//   promo_service_purchase_info: PromoServicePurchaseInfoDto = new PromoServicePurchaseInfoDto();
// }

//#endregion --- Promo Report Response DTOs ---

export class PromoReportSummaryDataExportRequestDto {
  public format: string = 'csv';
  public selected_field_names: string[] = [];
  public filter? : PromoReportSearchRequestDto = null;
}

export class PromoSummaryServiceQueryParamsBase {
  @Type(() => PromoReportSearchRequestDto)
  public promoReportSearchRequestDto: PromoReportSearchRequestDto;
}
export class PromoSummaryServiceQueryParams extends PromoSummaryServiceQueryParamsBase {
  @Type(() => PromoSummaryDto)
  public promoSummaryDto : PromoSummaryDto;
}

export class PromoSummaryServicePurchaseQueryParams extends PromoSummaryServiceQueryParamsBase {
  @Type(() => PromoSummaryServiceDto)
  public promoSummaryServiceDto : PromoSummaryServiceDto;
}

export class PromoSummaryServicePurchaseInfoQueryParams extends PromoSummaryServiceQueryParamsBase {
  @Type(() => PromoSummaryServicePurchaseDto)
  public promoSummaryServicePurchaseDto : PromoSummaryServicePurchaseDto;
}
