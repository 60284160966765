import { ColumnDataType, GridColumnItem } from "../interfaces/kendo-grid";
import { IColumnDto } from "../interfaces/search-service";

export const SEARCH_SERVICE_DATA_TYPE_MAPPINGS = [
  { key: "Boolean", value: "boolean" },
  { key: "Collections.Generic.HashSet`1[System.String]", value: "string" },
  { key: "Collections.Generic.IEnumerable`1[System.Guid]", value: "string" },
  { key: "DateTimeOffset", value: "date" },
  { key: "decimal", value: "number" },
  { key: "Decimal", value: "number" },
  { key: "Guid", value: "string" },
  { key: "int", value: "number" },
  { key: "Int32", value: "number" },
  { key: "Nullable`1[System.DateTimeOffset]", value: "date" },
  { key: "Nullable`1[System.Decimal]", value: "number" },
  { key: "Nullable`1[System.Guid]", value: "string" },
  { key: "Nullable`1[System.Int32]", value: "number" },
  { key: "string", value: "string" },
  { key: "String", value: "string" },
  { key: "String[]", value: "string" },
];

export const SEARCH_SERVICE_DATA_TYPE_MAP = new Map<string, string>(
  SEARCH_SERVICE_DATA_TYPE_MAPPINGS.map(({ key, value }) => [key, value]),
);

export function convertServiceColumnsToGridColumnItems(columns: IColumnDto[]): GridColumnItem[] {
  // Create an empty array to store the grid columns
  const result: GridColumnItem[] = [];

  // Iterate over the CUSTOMER_SERVICE_COLUMNS and extract the corresponding grid column
  columns.forEach((column: IColumnDto) => {
    const gridColumnItem: GridColumnItem = transformColumnToToGridColumnItem(column);
    result.push(gridColumnItem);
  });

  return result;
}

export function transformColumnToToGridColumnItem(column: IColumnDto): GridColumnItem {
  const heading = column.heading;
  const sub_field = column.sub_field;

  const isServiceIdColumn: boolean = heading === 'Service: Id';

  // Extract the data type from the grid column type
  const dataType: ColumnDataType = transformGridColumnDataType(column.type);

  const columnKey = `${column.field}`;

  // Create a new GridColumnItem object
  const gridColumnItem: GridColumnItem = new GridColumnItem(
    {field: `${columnKey}`, sub_field: sub_field, title: heading, filterable: true, hidden: isServiceIdColumn},
  );
  gridColumnItem.data_type = dataType;

  // Set the filter type based on the data type
  if (dataType === 'number')
    gridColumnItem.filter = 'numeric';
  else if (dataType === 'date')
    gridColumnItem.filter = 'date';
  else if (dataType === 'boolean')
    gridColumnItem.filter = 'boolean';

  return gridColumnItem;
}

export function transformGridColumnDataType(gridColumnType: string): ColumnDataType {
  // Extract the key from the input string (after the last ':') and use regex to delete all double quotes
  const key = gridColumnType.substring(gridColumnType.lastIndexOf(':') + 1).trim().replace(/"/g, '');

  // Get the corresponding data type from the Map
  const dataType = SEARCH_SERVICE_DATA_TYPE_MAP.get(key);

  // Handle missing types
  if (!dataType) 
    throw new Error(`Data Type '${key}' derived from '${gridColumnType}' not found in transformGridColumnDataType.`);  

  return dataType as ColumnDataType;
}

// eslint-disable-next-line complexity
export function getServiceStatusClass(serviceStatus: string): string {
  if (!serviceStatus)
    return '';

  switch (serviceStatus.toLowerCase()) {
    case 'expression of interest':
      return 'status-active';
    case 'active':
      return 'status-active';
    case 'order':
      return 'status-future-dated';
    case 'osp':
      return 'status-future-dated';
    case 'isp':
      return 'status-future-dated';
    case 'provisioning':
      return 'status-future-dated';
    case 'expiring':
      return 'status-active';
    case 'expired':
      return 'status-active';
    case 'cancellation in progress':
      return 'status-active';
    case 'cancelled':
      return 'status-deleted';
    case 'isp changed':
      return 'status-active';
    case 'isp change pending':
      return 'status-active';
    case 'product changed':
      return 'status-active';
    case 'product change pending':
      return 'status-active';
    case 'rejected':
      return 'status-deleted';
    case 'suspended':
      return 'status-future-dated';
    case 'deleted':
      return 'status-deleted';
    case 'activation in progress':
      return 'status-future-dated';
    case 'pending isp application':
      return 'status-future-dated';
    case 'pending installation':
      return 'status-future-dated';
    case 'pending isp activation':
      return 'status-future-dated';
    default:
      return 'status-active';
  }
}

export function getAreaStatusClass(areaStatus: string): string {
  if (!areaStatus)
    return '';
  switch (areaStatus.toLowerCase()) {
    case 'cancelled':
      return 'status-deleted';
    case 'connected':
      return 'status-active';
    case 'pre order':
      return 'status-active';
    case 'show your interest':
      return 'status-active';
    case 'suspended':
      return 'status-deleted';
    case 'on hold':
      return 'status-future-dated';
    default:
      return 'status-default';
  }
}
