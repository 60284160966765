import { IEnvironmentBase } from '@aex/ngx-toolbox';
import packageDef from '../../../../package.json';

export interface OperatorResolution {
	pattern: string;
}

export interface IEnvironment extends IEnvironmentBase {
	paymentServerUrl?: string;
	purchaseOrderServerUrl?: string;
	pricingServerUrl?: string;
	fnoServerUrl?: string;
	wifiServerUrl?: string;
	supportUrl?: string;
	operatorCredentials?: IBasicCredentials;
	operatorResolution: OperatorResolution[];
	kmlServer?: string;
	kmlVersion?: number;
	override?: IEnvironmentOverride;
	securityServiceUrl?: string;
	fusionSolarServerUrl?: string;
	weatherServerUrl?: string;
	loadSheddingServerUrl?: string;
	pushNotificationsServerUrl?: string;
	paymentServiceUrl?: string;
	purchaseOrderServiceUrl?: string;
	serverUrl?: string;
	nmsServerUrl?: string;
	ipPoolServerUrl?: string;
	ispServerUrl?: string;
	searchServiceUrl?: string;
	solarServiceUrl?: string;
	billingServiceUrl?: string;
	radiusServiceUrl?: string;
	supportServiceUrl?: string;
	wifiServiceUrl?: string;
	workOrderEventUrl?: string;
	eventsUrl?: string;
	speedTestUrl?: string;
	assetsServerUrl?: string;
	kmlServerUrl?: string;
	operator?: string;
	token?: string;
	configName?: string;
	notificationServiceUrl?: string;
	searchServerUrl?: string;
	sisenseReportUrl?: string;
}

export enum ConfigString {
	Stage = 'stage',
	QA = 'qa',
	Alpha = 'alpha',
	PreProd = 'preprod',
	Prod = 'prod',
}

export enum Operators {
	AutomationExchange = 'automationexchange',
	Axio = 'axio',
	Default = 'default',
	Evotel = 'evotel',
	GcPud = 'gcpud',
	HyperFiber = 'hyperfiber',
	Katiah = 'katiah',
	Linklayer = 'linklayer',
	MagnoliaTreeFibre = 'magnoliatreefibre',
	MetroWatt = 'metrowatt',
	MFibre = 'mfibre',
	MockFno = 'mockfno',
	NetNineNine = 'netninenine',
	NetStream = 'netstream',
	OpenFiberUsa = 'openfiberusa',
	OpenFibre = 'openfibre',
	PTZambia = 'ptzambia',
	Reflex = 'reflex',
	RippleFiber = 'ripplefiber',
	RiseTelecoms = 'risetelecoms',
	Unifybr = 'unifybr',
	Zoomfibre = 'zoomfibre',
	PrimeOne = 'primeone',
}

export interface IEnvironmentOverride {
	configFile?: string
	theme?: string
	operator?: string
	name?: string;
	configName?: ConfigString;
	googleApiKey?: string
	fnoPortalBaseUrl?: string,
	stripePublishableKey?: string,
}

interface IBasicCredentials {
	username: string;
	password: string;
}

export const DEV_GOOGLE_KEY = 'AIzaSyAIwIKhQiNvw3FdgWsyGG3-Rclf-QPnH48';

export function env(inbound?: Partial<IEnvironment>): Partial<IEnvironment> {
	return {
		name: 'dev',
		version: packageDef.version,
		useServiceWorker: true,
		production: false,
		mocksEnabled: false,
		...(inbound || {}),
	};
}

