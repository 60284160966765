import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DialogResult, DialogService} from "@progress/kendo-angular-dialog";
import {map} from "rxjs/operators";

@Injectable({
	providedIn: 'root',
})
export class DialogManagerService {

	constructor(private readonly dialogService: DialogService) {}

	public openYesNoDialog(
		title: string,
		message: string,
	): Observable<boolean> {
		const dialogRef = this.dialogService.open({
			title: title,
			content: message,
			actions: [
				{ text: 'No' },
				{ text: 'Yes', themeColor: 'primary' },
			],
		});
		return dialogRef.result.pipe(
			map(
				(dialogResult: DialogResult) => {
					//eslint-disable-next-line @typescript-eslint/no-explicit-any
					const textValue: string = (dialogResult as any)['text'];
					if (textValue)
						return textValue === 'Yes';
					return false;
				},
			),
		);
	}

	public openOkDialog(
		title: string,
		message: string,
	): Observable<boolean> {
		const dialogRef = this.dialogService.open({
			title: title,
			content: message,
			actions: [
				{ text: 'Ok', themeColor: 'primary' },
			],
		});
		return dialogRef.result.pipe(
			map(
				(_: DialogResult) => {
					//eslint-disable-next-line @typescript-eslint/no-explicit-any
					return true;
				},
			),
		);
	}

}
