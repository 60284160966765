import {DateHelper} from "./date-helper";

export class StringHelper {

	public static searchTextIsInObject(item: object, searchText: string): boolean {
		return Object.values(item).some((value) => {
			if (value !== null) { // Check for non-nullish values

				let formattedValue: string;

				if (value instanceof Object)
					return StringHelper.searchTextIsInObject(value as object, searchText);
				if (value instanceof Date)
					formattedValue = DateHelper.formatDate(value); 	// Format the Date object
				else if (typeof value === "string")
					formattedValue = value.toLowerCase();			// Convert string to lowercase
				else if (typeof value === "number")
					formattedValue = value.toString(); 				// Convert number to string
				else
					return false; 														// Skip non-stringifiable values

				return formattedValue.includes(searchText); // Ensure case-insensitivity
			}
			return false;
		});
	}
}
