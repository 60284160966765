import {
	clearAllDataCaches,
	filterNonNil,
	IAuthResponse,
	isTokenExpired,
	LoaderType,
} from '@aex/ngx-toolbox';
import { AuthApi, ProxyApi } from '@aex/shared/apis';
import {
	APP_ROUTES,
	AuthType,
	ENVIRONMENT_CONFIG_TOKEN,
	ICustomer,
	IEnvironment,
	ITokenResult,
	KEY_AUTH_TOKEN,
	OPERATOR_ID_CLAIM,
	ParamMetaData,
	ROLE_CLAIM_PREFIX,
	USER_ID_CLAIM,
} from '@aex/shared/common-lib';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse, HttpStatusCode } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { BaseConfigService } from './base-config.service';

@Injectable()
export class AuthService {

	protected readonly loggedInSubject = new BehaviorSubject<boolean>(null);
	public readonly loggedInStream = this.loggedInSubject.asObservable().pipe(filterNonNil());
	private readonly _authOverride: Subject<void>;
	public readonly authOverride: Observable<void>;

	private readonly methodotAllowedSubject = new BehaviorSubject<boolean>(false);
	public methodNotAllowed$ = this.methodotAllowedSubject.asObservable();

	constructor(
		protected readonly http: HttpClient,
		protected readonly router: Router,
		protected readonly configService: BaseConfigService,
		@Inject(ENVIRONMENT_CONFIG_TOKEN) protected readonly environment: IEnvironment,
	) {
		this._authOverride = new Subject();
		this.authOverride = this._authOverride.asObservable();
	}

	protected _authToken: string;
	public get authToken(): string {
		this._authToken = this._authToken ?? this.getToken(KEY_AUTH_TOKEN);
		if (this._authToken && isTokenExpired(this._authToken))
			this.bustCaches().subscribe();

		return !this._authToken && !this.environment.mocksEnabled ? null : this._authToken;
	}

	protected getToken(key: string): string {
		return key;
	}

	public get operatorId(): string {
		return this.configService.operatorId || '';
	}

	public get isLoggedIn(): boolean {
		return !!this.authToken;
	}

	public setAuthToken(value: string): void {
		this._authToken = value;
	}

	public setProxyToken(value: string): void {
		window.parent.postMessage({proxyToken: value}, '*');
	}

	public login(username: string, password: string, loader?: LoaderType): Observable<HttpResponse<IAuthResponse>> {
		const body = new URLSearchParams({username, password});

		return this.http.post<IAuthResponse>(AuthApi.auth, body.toString(), {
			headers: new HttpHeaders()
				.set('x-operator-id', this.configService.operatorId)
				.set('Content-Type', 'application/x-www-form-urlencoded')
				.set('X-Requested-With', 'XMLHttpRequest'),
					params: new ParamMetaData({handleError: 'authentication',
					authToken: AuthType.NONE,
					loader,
			}),
			observe: "response",
		}).pipe(
			tap(response => {
				if (!response.body.auth_token)
					throw new Error('No authentication token returned');
			}),
		);
	}

	public loginCustomerAuthToken(username: string, password: string): Observable<string> {
		return this.login(username, password).pipe(
			map(response => {
				const token = response.body.auth_token;
				this.setAuthToken(token);
				return token;
			}),
		);
	}

	public loginCustomer(username: string, password: string): Observable<ICustomer> {
		return this.loginCustomerAuthToken(username, password).pipe(
			switchMap(() : Observable<ICustomer> => {
				return this.doGetLoginCustomer(username);
			}),
			tap(() => {
				this.loggedInSubject.next(true);
			}),
			catchError((error: HttpErrorResponse) => {
				if (error.status === HttpStatusCode.MethodNotAllowed)
					this.methodotAllowedSubject.next(true);

				return throwError(() => error);
			}),
	);
	}

	public doGetLoginCustomer(username: string): Observable<ICustomer>{
		if (username) {
			// do nothing by default
		}

		return of(null);
	}

	public doGetLoginProvider(token: string): Observable<string>{
		if (token) {
			// do nothing by default
		}

		return of(null);
	}

	public loginProvider(): Observable<string> {
		return of(null);
	}

	public loginBoth(username: string, password: string): Observable<ICustomer> {
		return this.loginCustomer(username, password);
	}

	public getProxyToken(): Observable<IAuthResponse> {
		return this.http.post<IAuthResponse>(ProxyApi.proxy, null, {
			params: new ParamMetaData({authToken: AuthType.USER}),
		}).pipe(
				tap(res => {
					if (!res.auth_token)
						throw new Error('No authentication token returned');
					else
						this.setProxyToken(res.auth_token);
				}),
		);
	}

	public bustCaches(): Observable<void> {
		this.setAuthToken(null);
		return clearAllDataCaches();
	}

	public logout(): Observable<void> {
		this.loggedInSubject.next(false);
		return this.bustCaches();
	}

	public gotoLogin(returnUrl?: string): void {
		this.router.navigate([APP_ROUTES.login.path, {returnUrl: returnUrl || this.router.url}]).then();
	}

	protected parseToken(token: string): ITokenResult {
		return token ? JSON.parse(atob(token.split('.')[1])) : null;
	}
	public getClaim(name: string): string {
		let token = this.authToken;

		return this.parseToken(token)?.claims.map(c => c.split(':')).find(c => c[0] === name)[1];
	}
	public getOperatorIdClaim(): string{
		return this.getClaim(OPERATOR_ID_CLAIM);
	}
	public getUserIdClaim(): string {
		return this.getClaim(USER_ID_CLAIM);
	}

	public get roles(): string[] {
		const parsedToken = this.parseToken(this.authToken);

		return parsedToken.claims
			.filter(c => c.startsWith(ROLE_CLAIM_PREFIX))
			.map(c => c.toLocaleLowerCase());
	}

	public isInRole(role: string): boolean {
		return this.roles.includes(role);
	}
}
