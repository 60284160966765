import {
	Component, ContentChild,
	EventEmitter,
	Input,
	Output, TemplateRef,
	ViewChild,
} from '@angular/core';

import {
	ActivatedRoute,
	Router,
} from "@angular/router";
import {
	ColumnReorderEvent,
	FilterableSettings,
	GridComponent,
	GridDataResult,
	PageChangeEvent,
	PagerSettings,
	SelectableMode,
	SelectableSettings,
} from "@progress/kendo-angular-grid";
import {
	IPagedResponse,
	WithDestroy,
} from "@aex/ngx-toolbox";
import {
	CompositeFilterDescriptor,
	filterBy,
	orderBy,
	SortDescriptor,
} from "@progress/kendo-data-query";
import { FilterExpression } from "@progress/kendo-angular-filter";

import {
	cancelIcon, downloadIcon,
	filterIcon,
	searchIcon,
	selectAllIcon,
	windowRestoreIcon,
	xIcon,
} from "@progress/kendo-svg-icons";
import { instanceToInstance } from "class-transformer";
import { ToastrService } from "ngx-toastr";

import { getKendoEditorType, StringHelper, getGridVisibleColumnOrder,
	IDropDownButtonData,
	IPagedData,
	restoreGridVisibleColumnOrder,
} from "@aex/shared/common-lib";
import { GridStateViewService } from "../grid-state-view/grid-state-view.service";
import {
	GridColumnItem,
	GridState,
	IAdvanceGridSearch,
	ISearchData,
	LoadGridStateRequest,
	PAGE_SIZES,
} from "../../../../common-lib/src/interfaces/kendo-grid";
import {ConfigService} from "@aex/shared/root-services";

@Component({
	selector: 'app-advance-search-grid',
	templateUrl: './advance-search-grid.component.html',
	styleUrls: ['./advance-search-grid.component.scss'],
})
export class AdvanceSearchGridComponent extends WithDestroy() {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	@ContentChild('gridCellTemplate') gridCellTemplate: TemplateRef<any>;


	public readonly svgCancelIcon = cancelIcon;
	public readonly svgDownloadIcon = downloadIcon;
	public readonly svgFilterIcon = filterIcon;
	public readonly svgSearchIcon = searchIcon;
	public readonly svgXIcon = xIcon;
	public readonly svgWindowRestoreIcon = windowRestoreIcon;
	public readonly svgSelectAllIcon = selectAllIcon;

	public checkboxColumnWidth = 65;
	public minResizableWidth = 150;

	public dateFormat: string;

	constructor(
		protected readonly toastr: ToastrService,
		protected readonly router: Router,
		protected readonly route: ActivatedRoute,
		protected readonly gridStateViewService: GridStateViewService,
		protected readonly configService: ConfigService,
	) {
		super();
		this.noZombie(this.gridStateViewService.$loadGridState).subscribe(
			(request: LoadGridStateRequest)=>{
				this.loadGridState(request)
			},
		);

		this.dateFormat = this.configService.operatorDateFormat || 'MM-dd-yyyy HH:mm:ss';
	}

	@ViewChild('dataGrid', { static: true }) public dataGrid!: GridComponent

	public gridData: ISearchData[] = [];
	private _filteredGridData: ISearchData[] = [];
	public get filteredGridData(): ISearchData[] {
		return this._filteredGridData;
	};

	@Input() public set pagedData(value: IPagedData<ISearchData>){
		this.initialiseGridData(value);
		this.restoreLoadedGridState();
	}

	@Input() hasGridStateView: boolean = true;
	@Input() hasQuickActions: boolean = true;

	public get displayQuickActionContainer(): boolean{
		return this.hasGridStateView || this.hasQuickActions;
	}

	@Input() hasAdvancedSearch: boolean = true;

	public totalRecordCount: number = 0;

	public gridDataResult : GridDataResult = {
		data: this.filteredGridData,
		total: this.totalRecordCount,
	};

	public set filteredGridData(value: ISearchData[]) {
		this._filteredGridData = value;
		const gridDataResult = {
			data: this.filteredGridData,
			total: this.totalRecordCount,
		};
		this.gridDataResult = { ...gridDataResult };
	}

	private _columns: GridColumnItem[] = [];
	public get columns(): GridColumnItem[] {
		return this._columns;
	}
	@Input() public set columns(value: GridColumnItem[]){
		this._columns = value;
		this.selectableColumns = value.map((column) => column.title)
				.sort((a, b) => a.localeCompare(b));
		this.resetGridColumns();
		this.initialiseGridFilterFields();
	}

	@Input() public gridViewId : string;
	@Input()  public advanceFilterActive : boolean = false;

	@Output() public readonly fetchDataEvent : EventEmitter<{pageNumber: number,pageSize: number, skip: number}> = new EventEmitter();
	@Output() public readonly clearAdvanceSearchFilterEvent : EventEmitter<void> = new EventEmitter()
	@Output() public readonly loadGridStateEvent : EventEmitter<LoadGridStateRequest> = new EventEmitter<LoadGridStateRequest>();
	@Output() public readonly saveGridStateEvent : EventEmitter<GridState> = new EventEmitter<GridState>();
	@Output() public readonly setAdvanceSearchFilterItemEvent : EventEmitter<IAdvanceGridSearch> = new EventEmitter<IAdvanceGridSearch>();
	@Output() public readonly nameHyperlinkActionEvent : EventEmitter<{ fieldName: string, dataItem: ISearchData } > =
		new EventEmitter<{fieldName: string, dataItem: ISearchData}>();
	@Output() public readonly checkBoxSelectionChangeEvent : EventEmitter<string[]> = new EventEmitter<string[]>();
	@Output() public readonly gridPageChangeEvent : EventEmitter<PageChangeEvent> = new EventEmitter<PageChangeEvent>();

	public selectableColumns : string[] = [];
	public filteredGridColumns : string[] = [];
	public selectedColumns: string[] = [];
	public visibleColumns: GridColumnItem[] = [];

	// Pagination
	public pageSize = 20;
	public buttonCount = 5;
	public sizes = PAGE_SIZES
	public skip = 0;
	public currentPage = 1;

	@Input() public pageSettings : PagerSettings = {
		buttonCount: this.buttonCount,
		info: true,
		type: 'numeric',
		pageSizes: this.sizes,
		previousNext: true,
	}

	@Input() public isToggle : boolean = false;

	// Grid Filtering
	@Input() public sort: SortDescriptor[] = [ { field: 'name', dir: 'asc'} ]; 		// Grid Sort Value

	public showXLStyleFilter: FilterableSettings = 'menu';            		// Show the XL style filter

	public searchKeyword: string = '';                                		// Search keyword

	protected readonly defaultFilter: CompositeFilterDescriptor = { logic: 'and', filters: [] };

	@Input() public selectGridKeyId : string = '';     // Key Id for the Kendo Grid Select

	public gridFilterValue: CompositeFilterDescriptor = { ...this.defaultFilter };
	public gridFilterFields : FilterExpression[]= []

	public allSelected: boolean = false;                                  // All rows selected
	@Input() public showCheckboxColumn: boolean = false;                  // Show the checkbox column
	@Input() public gridSelectable : SelectableMode = 'single';           // 'single' | 'multiple'

	private _selectedItems: string[] = []
	public get selectedItems(): string[]
	{
		return this._selectedItems;
	}
	public set selectedItems(value : string[])  // Selected row items
	{
		this._selectedItems = value;
		const selectedCount = this.selectedItems.length;
		const totalCount = this.filteredGridData.length;
		this.allSelected = selectedCount > 0 && selectedCount === totalCount;

		this.checkBoxSelectionChangeEvent.emit(this._selectedItems);
	}

	public get selectableSettings() : SelectableSettings{
		return {
			checkboxOnly: this.showCheckboxColumn,
			mode: this.gridSelectable,
		};
	}


	@Input() public quickButtonDisabled: boolean = true;
	@Input() public quickActionOptions : IDropDownButtonData[] = [];
	@Input() public hideQuickTools: boolean = true;

	@Input() public exportOptions : IDropDownButtonData[] = [];

	public quickActionItemClick(itemSelected: IDropDownButtonData): void {
		itemSelected.action();
	}

	protected initialiseGridFilterFields() : void {
		const filterableColumns = this.columns.filter((column) => column.filterable);
		this.gridFilterFields = filterableColumns.map((column) => {
			return {
				field: column.field || '',
				title: column.title || 'Title Not Set',
				editor: getKendoEditorType(column.data_type),
			};
		});
	}

	protected initialiseGridData(promotionDiscountData: IPagedResponse<ISearchData>): void {
		this.gridData = promotionDiscountData.items;
		this.totalRecordCount = promotionDiscountData.total;
		this.filteredGridData = [...promotionDiscountData.items];
		this.onGridSortChange(this.sort);
	}

	public resetGridColumns(): void {
		this.filteredGridColumns = [...this.selectableColumns];
		const gridColumns = [...this.columns];
		const selectedColumns = [...gridColumns]
			.filter((column) => !column.hidden)
			.map((column) => column.title);

		this.selectedColumns = [...selectedColumns];

		this.visibleColumns = [...gridColumns];
	}

	public selectALLGridColumns(): void {
		const selectableColumns = this.columns.map((column) => column.title);
		this.selectedColumns = [...selectableColumns];
		this.selectableColumns = [...selectableColumns];
		this.updateVisibleGridColumns();
	}

	public onFilteredGridColumnChange(filter: string): void {
		const normalizedFilter = filter.toLowerCase();
		this.filteredGridColumns = this.selectableColumns.filter(column =>
			column.toLowerCase().includes(normalizedFilter),
		);
	}

	// protected getKendoEditorType(dataType: string): FilterEditor {
	// 	const editorMapping: { [key: string]: FilterEditor } = {
	// 		string: 	'string',
	// 		number: 	'number',
	// 		boolean: 	'boolean',
	// 		date: 		'date',
	// 	};
	// 	return editorMapping[dataType] || 'string'; // Default to 'string' for unknown types
	// }

	public onGridSortChange(event: SortDescriptor[]) {
		this.sort = event;

		const orderedData = orderBy(this.filteredGridData, this.sort)
		this.filteredGridData = [...orderedData];

		this.gridDataResult = {
			data: this.filteredGridData,
			total: this.totalRecordCount,
		};
	}

	// For client-side filtering
	public filterChange(filter: CompositeFilterDescriptor): void {
		this.gridFilterValue = filter;
		this.filteredGridData = this.filterGridData(this.gridData, filter);
	}

	protected filterGridData(data: ISearchData[], filter: CompositeFilterDescriptor): ISearchData[] {
		return filterBy(data, filter);
	}

	public onGridPageChange(event: PageChangeEvent) : void {
		this.pageSize = event.take;
		this.skip = event.skip;

		this.gridPageChangeEvent.emit(event);
	}

	public onGridColumnSelectionChange(): void {
		this.updateVisibleGridColumns();
	}

	public gridColumnReorderEvent(event: ColumnReorderEvent ): void {
		const sourceIndex = event.oldIndex - 1;
		const targetIndex = event.newIndex - 1;

		// Reorder the records in the visibleColumns array
		const reorderedColumns = [...this.visibleColumns];
		const [removed] = reorderedColumns.splice(sourceIndex, 1);
		reorderedColumns.splice(targetIndex, 0, removed);
		this.visibleColumns = [...reorderedColumns];

		// Updated the columns array
		this.columns = [...reorderedColumns];
	}

	public exportGridDataItemClick(itemSelected: IDropDownButtonData): void {
		itemSelected.action(this.visibleColumns);
	}

	protected fetchGridData(): void{
		this.fetchDataEvent.emit(
			{
				pageNumber: this.currentPage,
				pageSize: this.pageSize,
				skip: this.skip,
			},
		);
	}

	protected updateVisibleGridColumns(): void {
		const selectedSet = new Set(this.selectedColumns);
		const selectedColumns = instanceToInstance(this.columns);
		selectedColumns.forEach((column) => {
			column.hidden = !selectedSet.has(column.title);
		});

		this.visibleColumns = [...selectedColumns];
	}

	public onSearchKeywordChange() {
		const searchText = this.searchKeyword.trim().toLowerCase(); // Trim to avoid whitespace issues
		if (!searchText) {
			this.filteredGridData = [...this.gridData];
			return;
		}
		const filteredSearchKeywordData = this.gridData.filter((item) => {
			return StringHelper.searchTextIsInObject(item, searchText);
		});

		this.filteredGridData = [...filteredSearchKeywordData];
	}

	public clearSearch() : void {
		this.searchKeyword = '';
		this.onSearchKeywordChange();
	}

	// Toggle Checkbox Column Visibility
	// public toggleCheckboxColumn(): void {
	// 	this.showCheckboxColumn = !this.showCheckboxColumn;
	// }

	// Handle the keydown event in the search textbox
	// If we don't prevent the default action, the Enter key will trigger the Filter Window
	public handleKeydown(event: KeyboardEvent): void {
		if (event.key === 'Enter') {
			event.preventDefault();           // Prevent default action
			event.stopPropagation();          // Stop event bubbling
			this.onSearchKeywordChange();
		}
	}

	// Grid State
	public gridState : GridState = new GridState();                   // Grid State
	public loadedGridState : GridState | null = null;

	// To keep track of grid state name and lock state
	public gridViewLockState: boolean = false;
	public gridViewStateName: string = '';

	public get currentGridState() : GridState{
		const gridState: GridState = new GridState();
		gridState.gridViewStateName = this.gridViewStateName;
		gridState.lockState = this.gridViewLockState;
		gridState.selectableColumns = [...this.selectableColumns];
		gridState.columns = [...this.columns];
		gridState.visualColumnOrder = getGridVisibleColumnOrder(this.dataGrid);
		gridState.sort = [...this.sort];
		gridState.filter = { ...this.gridFilterValue };
		gridState.pageSize = this.pageSize;
		gridState.selectedColumns = [...this.selectedColumns];
		gridState.buttonCount = this.buttonCount;
		gridState.sizes = [...this.sizes];
		gridState.skip = this.skip;
		gridState.currentPage = this.currentPage;
		gridState.pageSettings = { ...this.pageSettings };
		gridState.searchKeyword = this.searchKeyword;

		// Double check these flags!
		gridState.hasAdvancedSearch = this.hasAdvancedSearch;
		gridState.advanceFilterActive = this.advanceFilterActive;

		return gridState;
	}

	public saveGridState(gridViewName : string): void {
		const gridState = this.currentGridState;
		gridState.gridViewStateName = gridViewName;
		// Has advanced search - indicates whether the flag
		gridState.hasAdvancedSearch = this.hasAdvancedSearch;
		gridState.advanceFilterActive = this.advanceFilterActive;
		this.saveGridStateEvent.emit(gridState);
	}

	public loadGridState(loadGridStateRequest : LoadGridStateRequest): void {
		if (!loadGridStateRequest)
			return;
		if (loadGridStateRequest.clearData)
		{
			this.cancelAdvSearchClick();
			this.loadedGridState = null;
			return;
		}

		if (!loadGridStateRequest.gridState) {
			this.loadedGridState = null;
			return;
		}

		const gridState = loadGridStateRequest.gridState;

		// Load the grid state
		this.gridViewStateName = loadGridStateRequest.gridViewStateName;
		this.columns = [...gridState.columns];
		this.selectableColumns = [...gridState.selectableColumns];
		this.selectedColumns = [...gridState.selectedColumns];
		this.sort = [...gridState.sort];
		this.gridFilterValue = { ...gridState.filter };
		this.pageSize = gridState.pageSize;
		this.skip = gridState.skip;
		this.buttonCount = gridState.buttonCount;
		this.sizes = [...gridState.sizes];
		this.currentPage = gridState.currentPage;
		this.pageSettings = { ...gridState.pageSettings };
		this.searchKeyword = gridState.searchKeyword;
		this.gridViewLockState = gridState.lockState;

		this.loadedGridState = instanceToInstance(gridState);
		this.loadGridStateEvent.emit(loadGridStateRequest);
	}

	protected restoreLoadedGridState(): void {
		if (!this.loadedGridState)
			return;
		this.visibleColumns = restoreGridVisibleColumnOrder(this.columns, this.loadedGridState.visualColumnOrder);
	}

	// public canDeactivate(): Observable<boolean> | boolean {
	// 	if (this.loadedGridState === null)
	// 		return true;
	//
	// 	const gridState : GridState = new GridState();
	// 	this.saveGridState(this.gridViewName);
	//
	// 	// Compare with loaded Grid State
	// 	//#todo:portal2 - check if this is done correctly
	// 	const isGridStateSame = isGridStateIdentical(this.loadedGridState, gridState);
	// 	if (isGridStateSame)
	// 		return true;
	//
	// 	const canDeactivateMessage = `Grid State has changed. Do you want to save the changes?`;
	// 	return this.dialogManagerService.openYesNoDialog(
	// 		'Grid State Changes',
	// 		canDeactivateMessage,
	// 	).pipe(
	// 		map((confirm: boolean) => !confirm), // Invert the result, as `true` means canDeactivate
	// 	);
	// }


	// public filterAdvSearchClick() : void {
	// 	this.advanceFilterActive = true;
	// 	this.currentPage = 1;
	// 	this.isToggle = false;
	// 	this.fetchGridData();
	// }

	protected clearFilterAdvSearchClick() : void {
		this.clearAdvanceSearchFilterEvent.emit();
	}
	public cancelAdvSearchClick() : void {
		this.clearFilterAdvSearchClick();
		this.isToggle = false;
		this.advanceFilterActive = false;
		this.currentPage = 1;
		this.fetchGridData();
	}
	public onAdvSearchExpand() : void {
		this.isToggle = true;
	}
	public onAdvSearchCollapse() : void {
		this.isToggle = false;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public toggleSelectAll(event: any): void {
		this.allSelected = event.target.checked;

		if (this.allSelected)
			// Select all rows: Get the Ids of all rows
			this.selectedItems = this.filteredGridData.map((item) => item[this.selectGridKeyId as keyof ISearchData]);
		else
			this.selectedItems = [];

		this.checkBoxSelectionChange();
	}

	public checkBoxSelectionChange():void{
		this.checkBoxSelectionChangeEvent.emit(this.selectedItems);
	}

	public nameHyperlinkAction(field: string, dataItem: ISearchData){
		this.nameHyperlinkActionEvent.emit({ fieldName: field, dataItem: dataItem });
	}

	public getDateFormat(type: 'date' | 'dateTime'): string {
		return type === 'date' ? this.dateFormat.split(' ')[0] : this.dateFormat;
	}


}
