import {Injectable} from "@angular/core";
import {GridState, LoadGridStateRequest} from "../../../../common-lib/src/interfaces/kendo-grid";
import {GridStateService} from "@aex/shared/common-lib";
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
	providedIn: 'root',
})
export class GridStateViewService {

	private readonly loadGridViewsSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
	public readonly $loadGridViews: Observable<string> = this.loadGridViewsSubject.asObservable();
	private readonly loadGridStateSubject: BehaviorSubject<LoadGridStateRequest | null> = new BehaviorSubject<LoadGridStateRequest>(null);
	public readonly $loadGridState: Observable<LoadGridStateRequest> = this.loadGridStateSubject.asObservable();

	constructor(
		private readonly gridStateService: GridStateService,
	)
	{}

	public saveGridViewState(gridId: string, state: GridState) {
		// Set the grid state
		this.gridStateService.saveState(gridId, state.gridViewStateName, state);

		const loadGridStateRequest : LoadGridStateRequest = {
			gridViewStateName: gridId,
			gridState: state,
			reloadData: false,
			clearData: false,
		};
		this.loadGridStateSubject.next(loadGridStateRequest);

		// Reload the grid views which will include the new/updated grid view
		this.loadGridViewsSubject.next(gridId);
	}

}
